/*
 * Copyright (C) con terra GmbH
 */

/* eslint-disable no-useless-escape */

/*
 * This is file is used to init the dojoConfig correctly.
 * This file must be loaded before any reference to dojo.js!
 * It makes all global configuration options available.
 */
// Add further common dojo config parameters here

var pageRoot = window.location.href
    // Replace query part
    .replace(/\?.*/, "")
    // Replace /what.html files and duplicated // at end
    .replace(/\/+([^/]*\.[^/]*)?$/, "");
var trim =
    (String.prototype.trim &&
        function (str) {
            return str.trim();
        }) ||
    function (str) {
        return str.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
    };
function isReplaced(val) {
    return !(typeof val === "string" && val.match(/^@/));
}
function replacedOrDefault(str, defaultValue, canBeEmpty) {
    return isReplaced(str) && (canBeEmpty || str !== "") ? str : defaultValue;
}
function isTrue(str) {
    return !!/true/i.test(str);
}
function arrValues(str) {
    return !str ? [] : nonEmptyValues(str.split(/\s*,\s*/));
}
function nonEmptyValues(arr) {
    for (var i = 0; i < arr.length; ++i) {
        if (!arr[i]) {
            arr.splice(i--, 1);
        }
    }
    return arr;
}
function contextRelativeUrl(url) {
    var absoluteUrl = url.match(/^[\w\+\.\-_]+:\/\//) || (url.charAt(0) === "/" && url.charAt(1) === "/");
    if (!absoluteUrl) {
        url = $apprt.contextBase + url.replace(/^\.?\//, "");
    }
    return url.replace(/\/+$/, "");
}
function chk(val, defaultValue) {
    if (!isReplaced(val)) {
        return defaultValue;
    }
    if (typeof val === "string") {
        if (val.match(/^true$/)) {
            return true;
        } else if (val.match(/^false$/)) {
            return false;
        }
    }
    return val;
}

function evalBlocked() {
    try {
        new Function();
        return false;
    } catch (e) {
        return true;
    }
}

function hasWebAssembly() {
    // taken from https://stackoverflow.com/questions/47879864/how-can-i-check-if-a-browser-supports-webassembly
    try {
        if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
            const module = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
            if (module instanceof WebAssembly.Module) {
                return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
            }
        }
    } catch (e) {
        // ignore exception
    }
    return false;
}

// Utility to merge properties
function mergeProps(target, source, transformer) {
    for (var prop in source) {
        if (Object.prototype.hasOwnProperty.call(source, prop)) {
            var val = target[prop];
            if (val && typeof val === "object" && !val.length) {
                // If object and not an array
                mergeProps(target[prop], source[prop], transformer);
            } else {
                if (transformer) {
                    target[prop] = transformer(prop, source[prop], target[prop]);
                } else {
                    target[prop] = source[prop];
                }
            }
        }
    }
}

function changeConfig(config, dConfig) {
    dConfig = dConfig || window.dojoConfig || ctDefaultDojoConfig();
    mergeProps(dConfig, config, function (name, source, target) {
        var v = chk(source, target);
        if (v === target || typeof v !== "string") {
            return v;
        }
        if (typeof target === "string") {
            return trim(v);
        }
        if (target === undefined || target === null) {
            return v;
        }
        if (target.length !== undefined) {
            // Is array
            return arrValues(v);
        }
        return v;
    });
}

function parseRules(rules, defaultProxy) {
    for (var i = 0; i < rules.length; ++i) {
        var rule = rules[i].split("|");
        var origin = rule[0];
        var url = defaultProxy;
        url = rule[1] ? contextRelativeUrl(rule[1]) : url;
        rules[i] = {
            // Old (esri)
            urlPrefix: origin,
            // New (apprt_request)
            origin: origin,
            proxyUrl: url
        };
    }
    return rules;
}

/**
 * Check whether the API version is 3.x
 * @param config
 * @returns {boolean}
 */
function isApprt3(config) {
    return checkPackages(config, /^apprt@[\^~]?3\..*$/);
}

/**
 * Check whether the API version is (=> 4.0.0 and < 4.6)
 * @param config
 * @returns {boolean}
 */
function isApprt4Prior4_6(config) {
    return checkPackages(config, /^apprt@[\^~]?((4\.[0-5])|(4\.[0-5]\.(\d{1,})))(-\w+)?$/);
}

/**
 * Check whether the apprt packages match a given API version specified as a regular expression.
 * @param config
 * @param regexMatcher
 * @returns {boolean}
 */
function checkPackages(config, regexMatcher) {
    var packages = config && config.ct && config.ct.amdPackages;
    if (!packages || !packages.length) {
        return false;
    }
    for (var i = 0, length = packages.length; i < length; ++i) {
        if (packages[i].match(regexMatcher)) {
            return true;
        }
    }
    return false;
}

function checkBackwardsCompatibilityToEsri3(currentConfig) {
    if (!isApprt3(currentConfig)) {
        return currentConfig;
    }
    var apprtReq = currentConfig.apprt && currentConfig.apprt.request;
    if (apprtReq) {
        // Add old apprt request properties
        apprtReq.alwaysUseProxy = isTrue(replacedOrDefault("@@proxy.use.always@@", "false"));
        var maxUrlLength = 2048;
        apprtReq.postLength = apprtReq.maxUrlLength || maxUrlLength;
        apprtReq.corsEnabledServers = arrValues(replacedOrDefault("@@proxy.cors.enabledServers@@", "", true));
        delete apprtReq.maxUrlLength;
        delete apprtReq.trustedServers;
    } else {
        apprtReq = {};
    }
    var esri = currentConfig.esri || {};
    var esriOut = {};

    var esriDefaults = {
        geometryService: replacedOrDefault(
            "@@geometry.service.url@@",
            "http://sampleserver3.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer"
        ),
        map: {
            layerNamePrefix: "layer",
            graphicsLayerNamePrefix: "graphicsLayer"
        },
        io: {
            errorHandler: function (error, io) {
                var msg = error.message || "";
                var canceled =
                    error.canceled || error.cancelled || msg.indexOf("canceled") > -1 || msg.indexOf("cancelled") > -1;
                if (canceled) {
                    error.canceled = error.cancelled = canceled;
                    return;
                }
                if (window.console) {
                    console.debug("ESRI IO Error during requesting url: " + (io && io.url) + " Msg: " + error, error);
                }
            },
            proxyUrl: apprtReq.proxyUrl,
            alwaysUseProxy: apprtReq.alwaysUseProxy,
            useCors: isTrue(replacedOrDefault("@@proxy.cors.enabled@@", "true")) ? "with-credentials" : false,
            proxyRules: apprtReq.proxyRules,
            corsEnabledServers: apprtReq.corsEnabledServers,
            corsDetection: isTrue(replacedOrDefault("@@proxy.cors.detection@@", "false")),
            timeout: apprtReq.timeout,
            postLength: apprtReq.postLength
        }
    };
    mergeProps(esriOut, { defaults: esriDefaults });
    mergeProps(esriOut, esri);
    currentConfig.esri = esriOut;
    return currentConfig;
}

function ensureBackwardsCompatPriorApprt4_6(currentConfig) {
    if (!isApprt4Prior4_6(currentConfig)) {
        return;
    }
    var apprtReq = currentConfig.apprt.request;
    apprtReq.forceProxy = isTrue(replacedOrDefault("@@proxy.use.always@@", "false"));
    apprtReq.useCors = isTrue(replacedOrDefault("@@proxy.cors.enabled@@", "true"));
    delete apprtReq.trustedServers;
    defaultConfig.apprt.request.corsEnabledServers = arrValues(
        replacedOrDefault("@@proxy.cors.enabledServers@@", "", true)
    );
}

function removeUnsupportedPropertiesForApprt3(currentConfig) {
    if (!isApprt3(currentConfig)) {
        return;
    }

    // apprt-request@^3 throws an exception for unknown config properties :(
    if (currentConfig?.apprt?.request?.returnDojoDeferred !== undefined) {
        delete currentConfig.apprt.request.returnDojoDeferred;
    }
}

// The default dojoConfig
var defaultConfig;
function ctDefaultDojoConfig() {
    if (defaultConfig) {
        return defaultConfig;
    }

    var proxy = contextRelativeUrl(replacedOrDefault("@@proxy.service.location@@", "/proxy", true));
    return (defaultConfig = {
        // Enable the AMD format
        async: true,
        // Globals needed because of error in dojox/xml
        noGlobals: false,
        tlmSiblingOfDojo: false,
        // AMD module load timeout
        waitSeconds: 30,
        // Append date to each js loading url to prevent caching
        // this leads to a lot of errors during url calculation!
        cacheBust: false,
        parseOnLoad: false,
        isDebug: isTrue(replacedOrDefault("@@dojo.debug@@", "false")),
        has: {
            // only for backwards compat
            "ct-debug": isTrue(replacedOrDefault("@@dojo.debug@@", "false")),
            "ct-log-deprecations": isTrue(replacedOrDefault("@@dojo.debug@@", "false")),
            "ct-esri-mouseevents-click-tolerance": parseInt(
                replacedOrDefault("@@client.mouseevents.tolerance@@", "3"),
                10
            ),
            "ct-dgrid-touchscroll": isTrue(replacedOrDefault("@@client.dgrid.touchscroll@@", "false")),
            "ct-fix-viewport": isTrue(replacedOrDefault("@@client.config.mobile.fixviewport@@", "true")),
            "extend-esri": isTrue(replacedOrDefault("@@esri.api.extend-esri@@", "true")),
            "dojo-firebug": isTrue(replacedOrDefault("@@dojo.debug@@", "false")),
            "dojo-debug-messages": isTrue(replacedOrDefault("@@dojo.debug@@", "false")),
            "esri-featurelayer-webgl": isTrue(replacedOrDefault("@@esri.api.esri-featurelayer-webgl@@", "true")),
            "esri-native-promise": 1,
            "esri-workers-for-memory-layers": 1,
            "config-deferredInstrumentation": isTrue(replacedOrDefault("@@dojo.debug@@", "false")),
            "csp-restrictions": evalBlocked(),
            "webassembly": hasWebAssembly()
        },
        // Use ie7 popup for debug console
        popup: true,
        // Here the default locale is specified which is used if not set by request parameter
        locale: replacedOrDefault("@@client.config.defaultLocale@@", "en"),
        gfxRenderer: replacedOrDefault("@@client.config.gfx.renderer@@", undefined),
        // `dojox.mobile` settings, prevent default hiding of browser addressbar
        // fixes also IFrame-Resize problems on IPad
        mblHideAddressBar: false,
        apprt: {
            request: {
                // Cross domain post using proxy
                proxyUrl: proxy,
                proxyRules: parseRules(arrValues(replacedOrDefault("@@proxy.use.rules@@", "", true)), proxy),
                timeout: Number(replacedOrDefault("@@client.config.requestTimeout@@", "60000")),
                trustedServers: arrValues(replacedOrDefault("@@proxy.cors.trustedServers@@", "", true)),
                // Indicator when GET is transformed into post
                maxUrlLength: Number(replacedOrDefault("@@client.config.requestMaxUrlLength@@", "2048")),
                returnDojoDeferred: isTrue(replacedOrDefault("@@client.config.requestReturnDojoDeferred@@", "false"))
            }
        },
        // global ct specific configs
        ct: {
            applicationBaseURL: contextRelativeUrl(""),
            logging: replacedOrDefault("@@client.config.logging@@", ""),
            supportedLocales: arrValues(replacedOrDefault("@@client.config.supportedLocales@@", "en,de")),
            ensureLangParameter: true,
            useJSONPForConfigFiles: isTrue(replacedOrDefault("@@client.config.jsonp.loading@@", "false")),
            enableStatistics: isTrue(replacedOrDefault("@@client.config.statistics.enabled@@", "false")),
            preFetchBundles: isTrue(replacedOrDefault("@@client.config.preFetchBundles@@", "false")),
            preFetchMain: isTrue(replacedOrDefault("@@client.config.preFetchMain@@", "false")),
            prefetchUsingCompressedParams: isTrue(
                replacedOrDefault("@@client.config.prefetchUsingCompressedParams@@", "true")
            ),
            jsregistry: contextRelativeUrl(
                replacedOrDefault("@@jsregistry.service.url@@/root", "./resources/jsregistry/root", true)
            ),
            amdPackages: arrValues(replacedOrDefault("@@client.config.amdPackages@@", "apprt", true)),
            mainLayerFiles: arrValues(
                replacedOrDefault("@@client.config.mainlayerfiles@@", "apprt/launch/layer-min", true)
            ),
            arcgisPortalUrl: replacedOrDefault("@@esri.api.arcgisPortalUrl@@", "https://www.arcgis.com"),
            allowCredentialsOverHTTP: isTrue(replacedOrDefault("@@client.config.allowCredentialsOverHTTP@@", "false")),
            persistIdentityManagerState: isTrue(
                replacedOrDefault("@@client.config.persistIdentityManagerState@@", "false")
            ),
            preConfiguredAgolTokenURL: contextRelativeUrl(
                replacedOrDefault("@@security.user.agol.tokenurl@@", "", true)
            ),
            usePreConfiguredAgolToken: isTrue(replacedOrDefault("@@security.user.agol.enabled@@", "false")),
            weinreEnabled: isTrue(replacedOrDefault("@@client.config.weinre.enabled@@", "false")),
            weinreUrl: replacedOrDefault("@@client.config.weinre.url@@", "", true),
            // Following properties are meant to be overwritten in index.html files
            oauthEnabled: false,
            oauthAppId: "",
            oauthUsePopup: false,
            oauthCallbackPage: contextRelativeUrl("/account/oauth-callback.html"),
            oauthNamespace: undefined,
            oauthExpiration: undefined,
            oauthForceLogin: false,
            geolocationUpdateInterval: Number(replacedOrDefault("@@client.config.geolocationUpdateInterval@@", "10000"))
        }
    });
}
function addJSScript(location, isContent) {
    var doc = window.document;
    var head = doc.getElementsByTagName("head")[0];
    var s = doc.createElement("script");
    if (isContent) {
        s.appendChild(doc.createTextNode(location));
    } else {
        s.setAttribute("src", location);
    }
    head.appendChild(s);
}
function concat(a, b) {
    return !a ? b : a.concat(b);
}
function checkLanguageParameter(dConfig) {
    // Here we check the request parameters to initiate the locale
    // if not found the default locale is used (see above) or if not specified the browsers locale
    const search = location.search;
    const langParamValue = search.replace(/(?:.*[\?&](?:lang)=([^&]*).*)|.+/, "$1");
    const supportedLocales = (dConfig.ct.supportedLocales || []).map(l=>l.toLowerCase());
    function lookupSupported(locale) {
        if (!locale) {
            return undefined;
        }
        const parts = locale.split(/[-_]/);
        const lang = parts[0].toLowerCase();
        const country = (parts[1] ?? "").toUpperCase();
        const localeStr = country ? `${lang}-${country}` : lang;
        if (supportedLocales.includes(localeStr.toLowerCase())) {
            return localeStr;
        }
        if (country && supportedLocales.includes(lang)) {
            return lang;
        }
        return undefined;
    }
    let locale = lookupSupported(langParamValue);
    if (!locale) {
        const browserLocales = getBrowserLocales();
        for (const browserLocale of browserLocales) {
            locale = lookupSupported(browserLocale);
            if (locale) {
                break;
            }
        }
    }
    if (locale) {
        dConfig.locale = locale;
    }
    if (!dConfig.locale) {
        // hardcode full default
        dConfig.locale = "en";
    }

    const selectedLang = dConfig.locale;
    if (!langParamValue) {
        // This forces a refresh!
        location.search = search + (search ? "&" : "?") + "lang=" + selectedLang;
        return true;
    } else if (selectedLang !== langParamValue) {
        // This forces a refresh!
        location.search = search.replace(/([\?&](?:lang)=)([^&]*)/, "$1" + selectedLang);
        return true;
    }
}

function getBrowserLocales() {
    const nav = window.navigator;
    if (!nav) {
        return [];
    }

    const languages =
        (navigator.languages?.length ? navigator.languages : undefined) ?? // Modern
        (navigator.language ? [navigator.language] : undefined) ?? // Fallback
        (navigator.userLanguage ? [navigator.userLanguage] : []); // IE

    // may contain de or de-CH or en-US
    return languages;
}

function initDojoConfig(dConfig, callback) {
    dConfig = dConfig || window.dojoConfig || {};
    var config = {};
    mergeProps(config, ctDefaultDojoConfig());
    // Merge external properties into defaults
    mergeProps(config, dConfig);
    // Ensure backwards compatibility
    checkBackwardsCompatibilityToEsri3(config);
    ensureBackwardsCompatPriorApprt4_6(config);
    removeUnsupportedPropertiesForApprt3(config);

    // Register new dojoConfig
    dConfig = window.dojoConfig = config;

    var ctConfig = dConfig.ct || {};
    if (ctConfig.ensureLangParameter && checkLanguageParameter(dConfig)) {
        // Refresh triggered
        return;
    }
    // `weinre` is directly added if enabled
    if (ctConfig.weinreEnabled && isReplaced(ctConfig.weinreUrl)) {
        addJSScript(ctConfig.weinreUrl);
    }

    if (ctConfig.dojoInitialized) {
        callback && callback(dConfig);
        return;
    }
    ctConfig.dojoInitialized = true;

    if (window) {
        // overwrite global event handler
        // this ensures correct behavior in Edge and Firefox and Chrome
        window.onunhandledrejection = (event) => {
            const reason = event.reason;
            if (!reason) {
                return;
            }
            if (reason.dojoType === "cancel" || reason.name === "AbortError" || reason.cancelled) {
                // do not report cancel errors
                event.preventDefault();
                return;
            }
            console.warn("Unhandled promise rejection:", event.reason);
            if (!dConfig.isDebug) {
                event.preventDefault();
            }
        };
    }

    var amdPackages = ctConfig.amdPackages;
    var defaultPackages = [];
    //TODO: with dojo 1.8 prefixes are not allowed to have '/' signs -> may simplify the regex
    // group 1 = prefix (also scoped package)
    // group 2 = version
    // group 3 = url
    // group 4 = main
    // group 5 = url (no main configured)
    // eslint-disable-next-line max-len
    var packageConfigReg = /^\s*((?:@[a-zA-Z0-9\-_$.]+\/[a-zA-Z0-9\-_$.]+)|(?:[a-zA-Z0-9\-_$.]+))(?:@([^:]+))?\s*(?::\s*(?:(?:(\S+)\s*:\s*([a-zA-Z0-9\-_$.@/]+))|(\S+))\s*)?$/;
    var jsregistryPackageLookups = [];
    for (var i = 0, l = amdPackages.length; i < l; ++i) {
        var amdDef = amdPackages[i];
        var parts = packageConfigReg.exec(amdDef);
        if (!parts) {
            console.error("ERROR: amd package '" + amdDef + "' has wrong format!");
            continue;
        }
        var name = trim(parts[1]);
        var version = trim(parts[2] || "");
        var loc = trim(parts[3] || parts[5] || "");
        var main = trim(parts[4] || "") || "main";
        if (!loc) {
            jsregistryPackageLookups.push(name + (version ? "@" + version : ""));
        } else {
            defaultPackages.push({
                name: name,
                location: contextRelativeUrl(loc.replace(/\/+$/, "")),
                main: main
            });
        }
    }
    defaultPackages.push({
        name: "appPage",
        location: pageRoot
    });
    defaultPackages.push({
        name: "appContext",
        location: contextRelativeUrl("")
    });
    defaultPackages.push({
        name: "builderapps",
        location: contextRelativeUrl("/resources/apps")
    });
    dConfig.packages = concat(dConfig.packages, defaultPackages);
    var requires = ctConfig.mainLayerFiles.slice(0);
    dConfig.deps = concat(dConfig.deps, requires);
    if (jsregistryPackageLookups.length) {
        window.__mergePackages = function (packs) {
            if (!packs.length) {
                console.error("ERROR: amd packages '" + jsregistryPackageLookups + "' not found!");
            }
            var registeredPacks = dConfig.packages;
            var nameLookup = {};
            for (var i = 0, j = registeredPacks.length; i < j; ++i) {
                nameLookup[registeredPacks[i].name] = 1;
            }
            for (var k = 0, l = packs.length; k < l; ++k) {
                if (!nameLookup[packs[k].name]) {
                    registeredPacks.push(packs[k]);
                }
            }
            if (!nameLookup.bundles) {
                registeredPacks.push({
                    name: "bundles",
                    location: ctConfig.jsregistry
                });
            }
            callback && callback(dConfig);
        };
        addJSScript(
            ctConfig.jsregistry +
                "/packages.json?requires=" +
                encodeURIComponent(jsregistryPackageLookups.join(",")) +
                "&callback=__mergePackages"
        );
    } else {
        callback && callback(dConfig);
    }
}

function convertDeps(deps, packageLookup) {
    deps = deps.slice(0);
    for (var i = 0, l = deps.length; i < l; ++i) {
        deps[i] = deps[i].replace(/([\^+])?([^/]+)(\/.*)/g, function (match, prefix, packageName, postfix) {
            var pack = packageLookup[packageName];
            if (pack && pack.version) {
                return (prefix || "") + pack.name + "@" + pack.version + postfix;
            }
            return match;
        });
    }
    return deps;
}

function afterMapAppsLoad(initCallback, doNotLoadLauncher) {
    var dConfig = window.dojoConfig;
    initDojoConfig(dConfig, function (dConfig) {
        var orgDeps = (dConfig.deps || []).slice(0);
        var oldCallback = dConfig.callback;
        dConfig.callback = function () {
            // Call original callback first
            if (oldCallback) {
                oldCallback.call(this, arguments);
            }
            var requires = orgDeps;
            var launcher = "";
            if (!doNotLoadLauncher) {
                launcher = "apprt/launch/Launcher";
                requires.push(launcher);
            }
            require(requires, function () {
                if (launcher) {
                    const LauncherClazz = require(launcher);
                    initCallback(LauncherClazz.default || LauncherClazz);
                } else {
                    initCallback();
                }
            });
        };
        var ctConfig = dConfig.ct;
        var prefetch = ctConfig.preFetchBundles;
        var registryUrl = ctConfig.jsregistry;
        var locale = dConfig.locale;
        var packageLookup = {};
        var configuredPackages = dConfig.packages;
        for (var i = 0, l = configuredPackages.length; i < l; ++i) {
            packageLookup[configuredPackages[i].name] = configuredPackages[i];
        }
        // Produce error if dojo not registered
        var startScript = "/dojo.js";
        var packages = dConfig.packages;
        for (var j = 0; j < packages.length; j++) {
            if (packages[j].name === "dojo") {
                startScript = packages[j].location + startScript;
                break;
            }
        }
        var deps = convertDeps(dConfig.deps, packageLookup);
        var skip = convertDeps(["dojo/dojo"], packageLookup);
        ctConfig._loaderInfo = { required: deps, required_skip: skip, locale: locale };
        if (prefetch && registryUrl) {
            dConfig.deps = [
                registryUrl +
                    "/layer.js?requires=" +
                    encodeURIComponent(deps.join(",")) +
                    "&requires_skip=" +
                    encodeURIComponent(skip.join(",")) +
                    "&lang=" +
                    locale +
                    "&t="
            ];
        }
        // Now add init script
        addJSScript(startScript);
    });
}

function startApp(options, callback, errCallback) {
    afterMapAppsLoad(function (Launcher) {
        var launcher = new Launcher(options);
        var whenLaunched = options.app
            ? launcher.launchApp(options.app, options.domId)
            : launcher.launchAppFromParam(options);
        whenLaunched.then(callback, errCallback);
    });
}

// New single global
var $apprt = (window.$apprt = {
    startApp: startApp,
    load: afterMapAppsLoad,
    initDojo: initDojoConfig,
    changeConfig: changeConfig,
    defaultConfig: ctDefaultDojoConfig,
    contextRelativeUrl: contextRelativeUrl,
    // Allow external configuration of the context base
    contextBase: replacedOrDefault("@@application.base.url@@", pageRoot) + "/"
});
